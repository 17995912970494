'use client';

import { Button } from '@/components/ui/button';
import Link from 'next/link';
import React, { useState } from 'react';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Loader2 } from 'lucide-react';
import { createClient } from '@/utils/supabase/client';
import { toast } from 'sonner';
import { useRouter, useSearchParams } from 'next/navigation';

// Define prop type with allowEmail boolean
interface PasswordSignInProps {
  allowEmail: boolean;
}

export default function PasswordSignIn({ allowEmail }: PasswordSignInProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const router = useRouter();
  const searchParams = useSearchParams();
  const nextUrl = searchParams.get('next');

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true); // Disable the button while the request is being handled

    const formData = new FormData(e.currentTarget);
    const email = formData.get('email') as string;
    const password = formData.get('password') as string;

    const supabase = createClient();
    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password
    });

    if (error) {
      toast.error(error.message);
    } else {
      toast.success('Successfully signed in');
      router.push(nextUrl || '/dashboard');
    }
    setIsSubmitting(false);
  };

  return (
    <div>
      <form
        noValidate={true}
        className="mb-4 grid gap-2"
        onSubmit={(e) => handleSubmit(e)}
      >
        <div>
          <Label htmlFor="email">Email</Label>
          <Input
            id="email"
            placeholder="name@example.com"
            type="email"
            name="email"
            autoCapitalize="none"
            autoComplete="email"
            autoCorrect="off"
            className="bg-gray-200"
          />
        </div>

        <div className="relative">
          <Label htmlFor="password">Password</Label>
          <Link
            href="/forgot-password"
            className="font-light text-sm hover:underline absolute right-0 top-0.5"
          >
            Forgot your password?
          </Link>
          <Input
            id="password"
            placeholder="Password"
            type="password"
            name="password"
            autoComplete="current-password"
            className="bg-gray-200"
          />
        </div>

        <Button type="submit" className="mt-2" variant="outline">
          {isSubmitting ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              Signing in...
            </>
          ) : (
            'Sign in'
          )}
        </Button>
      </form>

      {allowEmail && (
        <p className="text-center">
          <Link
            href={`/login?method=email${nextUrl ? `&next=${nextUrl}` : ''}`}
            style={{ textDecoration: 'underline' }}
          >
            Or sign in via magic link
          </Link>
        </p>
      )}
    </div>
  );
}
