'use client';

import { Button } from '@/components/ui/button';
import Link from 'next/link';
import { signInWithEmail } from '@/utils/auth-helpers/server';
import { useState } from 'react';
import { handleSimpleResponse } from '@/utils/helpers';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { useSearchParams } from 'next/navigation';

// Define prop type with allowPassword boolean
interface EmailSignInProps {
  allowPassword: boolean;
}

export default function EmailSignIn({ allowPassword }: EmailSignInProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const searchParams = useSearchParams();
  const nextUrl = searchParams.get('next');

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    const formData = new FormData(e.currentTarget);
    if (nextUrl) {
      formData.append('next', nextUrl);
    }
    const res = await signInWithEmail(formData);
    handleSimpleResponse(res);
    setIsSubmitting(false);
  };

  return (
    <div>
      <form
        noValidate={true}
        className="mb-4"
        onSubmit={(e) => handleSubmit(e)}
      >
        <div className="grid gap-4">
          <div className="grid gap-1">
            <Label htmlFor="email">Email</Label>
            <Input
              id="email"
              placeholder="name@example.com"
              type="email"
              name="email"
              autoCapitalize="none"
              autoComplete="email"
              autoCorrect="off"
              className="w-full p-3 rounded-lg bg-gray-200"
            />
          </div>
          <Button
            variant="outline"
            type="submit"
            className="mt-1 text-md"
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Sending link...' : 'Sign in'}
          </Button>
        </div>
      </form>

      {allowPassword && (
        <>
          <p className="text-center">
            <Link
              href={`/login?method=password${nextUrl ? `&next=${nextUrl}` : ''}`}
              style={{ textDecoration: 'underline' }}
            >
              Or sign in with email and password
            </Link>
          </p>
        </>
      )}
    </div>
  );
}
